// internationalization dictionary

const messages = {
  en: {
    message: {
      hello: 'Hello world'
    }
  },
  fr: {
    message: {
      hello: 'Bonjour monde'
    }
  }
};

module.exports = messages;
