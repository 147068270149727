import Vue from 'vue';
import Router from 'vue-router';

// Containers
const MSIContainer = () => import('@/containers/MSIContainer');

// Views
const ApiTester = () => import('@/views/ApiTester');
const BrokerTester = () => import('@/views/BrokerTester');
const Performance = () => import('@/views/Performance');
const JwtExplorer = () => import('@/views/JwtExplorer');
const GatewayImage = () => import('@/views/GatewayImage');
const WeatherFileUpload = () => import('@/views/WeatherFileUpload');
const DaqSiteSetup = () => import('@/views/daq/SiteSetup');
const BLTUpload = () => import('@/views/BLTUpload');
const FATConfig = () => import('@/views/FATConfig');
// Error Pages
const Error404 = () => import('@/views/errors/Page404');

Vue.use(Router);

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/apitester',
      name: 'Home',
      component: MSIContainer,
      children: [
        {
          path: 'apitester',
          name: 'API Tester',
          component: ApiTester
        },
        {
          path: 'broker',
          name: 'Broker Tester',
          component: BrokerTester
        },
        {
          path: 'performance',
          name: 'Performance',
          component: Performance
        },
        {
          path: 'jwt',
          name: 'JWT Explorer',
          component: JwtExplorer
        },
        {
          path: 'gateway-image',
          name: 'Gateway Image',
          component: GatewayImage
        },
        {
          path: 'upload',
          name: 'File Upload',
          component: WeatherFileUpload
        },
        {
          path: 'daq-site-setup',
          name: 'Daq Site Setup',
          component: DaqSiteSetup
        },
        {
          path: 'blt-upload',
          name: 'Board Level Test Upload',
          component: BLTUpload
        },
        {
          path: 'fat-config',
          name: 'DAQ FAT configuration',
          component: FATConfig
        }
      ]
    },
    {
      path: '*',
      name: 'Error 404',
      component: Error404
    }
  ]
});


export default router;
