<script>
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import 'javascript-detect-element-resize';
import loadExporting from 'highcharts/modules/exporting';
import loadExportData from 'highcharts/modules/export-data';
import loadNoData from 'highcharts/modules/no-data-to-display';

loadExporting(Highcharts);
loadExportData(Highcharts);
loadNoData(Highcharts);

export default {
  extends: Chart,
  methods: {
    onResize() {
      if (this.chart) {
        this.chart.reflow();
      }
    }
  },
  mounted(){
    this.options.chart.events = this.options.chart.events || {};
    this.options.chart.events.redraw = () => this.$emit('render');
    Chart.mounted.call(this);
    window.addResizeListener(this.$el, this.onResize);
  }
};
</script>

<style>
  .card-body div[data-highcharts-chart],
  .card-body div[data-highcharts-chart] .highcharts-container{
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }


</style>
