// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/es6/promise';
import 'core-js/es6/string';
import 'core-js/es7/array';
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import BootstrapVue from 'bootstrap-vue';
import VueI18n from 'vue-i18n';
import Highcharts from 'highcharts';
import Datetime from 'vue-datetime';
import VTooltip from 'v-tooltip';

// import fontawesome for vue
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import App from './App.vue';
import store from './store';
import router from './router';
import messages from './languages/messages';
import init from './init';

import HighChartsRepsonsive from './components/HighChartsResponsive.vue';

Highcharts.setOptions({
  global: {
    useUTC: false
  }
});

Vue.use(VueCompositionAPI);
Vue.use(BootstrapVue);
Vue.use(VueI18n);
Vue.use(Datetime);
Vue.use(VTooltip);

Vue.component('highcharts', HighChartsRepsonsive);

// add all fontawesome icons
library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// instantiate the internationalization library
// set the default language to english ('en')
const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en',
  messages // set locale messages
});

init().then(() => {
  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    store,
    router,
    i18n,
    template: '<App/>',
    components: {
      App
    }
  });

});
