let Vue;

async function FetchPlugin(url, options = {}) {
  const config = {};

  const headers = options.headers || {};
  let includeCreds = false;
  if (options.kauth == null || options.kauth === 'auto') {
    includeCreds = /http(s)?:\/\/([\w-]+\.)*morgansolar\.(xyz|com|ca)(\/.*)?/.test(url) || url.startsWith('http://localhost');
  } else {
    includeCreds = options.kauth === 'include';
  }

  // ensure that the token information isn't included in all fetch requests
  if (includeCreds) {
    const kauth = Vue.prototype.$kauth;
    const token = await kauth.getValidToken();
    headers.Authorization = `Bearer ${token}`;
  }

  config.headers = headers;
  config.method = options.method || 'GET';
  config.cache = options.cache || 'no-cache';

  if (options.body && (config.method !== 'GET' || config.method !== 'HEAD')) {
    config.headers['Content-Type'] = 'application/json; charset=utf-8';
    config.body = JSON.stringify(options.body);
  }

  const response = await fetch(url, config);
  if (options.text == null || options.text) {
    return response.text();
  }
  return response;
}

FetchPlugin.install = (_Vue) => {
  if (Vue && _Vue === Vue) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(
        '[fetch] already installed. Vue.use(FetchPlugin) should be called only once.'
      );
    }
    return;
  }
  Vue = _Vue;
  Vue.prototype.$fetch = FetchPlugin;
};

export default FetchPlugin;
