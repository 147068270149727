import qs from 'querystring';
import { fetch } from 'whatwg-fetch';
import AbortControllerPolyfill from 'abortcontroller-polyfill/src/abortcontroller';
import { polyfillNeeded as abortPolyfillNeeded } from 'abortcontroller-polyfill/src/utils';

let Vue;

const AbortController = abortPolyfillNeeded(window) ? AbortControllerPolyfill : window.AbortController;

class ApiPlugin {
  constructor(name, baseUrl, requestIntercept = opt => opt) {
    this.name = name;
    this.baseUrl = baseUrl;
    this.requestIntercept = requestIntercept;
    this.AbortController = AbortController;
  }

  async get(path, options, includeStatus = false){
    try {
      const res = await this.request('GET', path, options);

      if (!includeStatus) {
        if (res.status >= 200 && res.status <= 299) return res.json();
        throw new Error();
      } else {
        const body = await res.json();
        return { status: res.status, body };
      }
    } catch (e) {
      throw new Error(e);
    }
  }

  async post(path, options, includeStatus = false){
    try {
      const res = await this.request('POST', path, options);

      if (!includeStatus) {
        if (res.status >= 200 && res.status <= 299) return res.json();
        throw new Error();
      } else {
        const body = await res.json();
        return { status: res.status, body };
      }
    } catch (e) {
      throw new Error(e);
    }
  }

  async put(path, options, includeStatus = false){
    try {
      const res = await this.request('PUT', path, options);

      if (!includeStatus) {
        if (res.status >= 200 && res.status <= 299) return res.json();
        throw new Error();
      } else {
        const body = await res.json();
        return { status: res.status, body };
      }
    } catch (e) {
      throw new Error(e);
    }
  }

  async delete(path, options, includeStatus = false){
    try {
      const res = await this.request('DELETE', path, options);

      if (!includeStatus) {
        if (res.status >= 200 && res.status <= 299) return res.json();
        throw new Error();
      } else {
        const body = await res.json();
        return { status: res.status, body };
      }
    } catch (e) {
      throw new Error(e);
    }
  }

  async request(method, path, options = {}) {
    options = await this.requestIntercept(options);
    const queryStr = options.query ? `?${qs.stringify(options.query)}` : '';
    const url = `${this.baseUrl}${path}${queryStr}`;
    options.method = method;
    if (options.body && (method !== 'GET' || method !== 'HEAD')) {
      options.headers['Content-Type'] = 'application/json; charset=utf-8';
      options.body = JSON.stringify(options.body);
    }

    return fetch(url, options);
  }

  // Vue specific code required to hide away 'installing' the broker functionality on the Vue prototype
  install(_Vue) {
    Vue = _Vue;
    Vue.prototype[`$${this.name}`] = this;
  }
}

export default ApiPlugin;
