import Vue from 'vue';
import KeycloakPlugin from './plugins/keycloak';
import ApiPlugin from './plugins/api';
import FetchPlugin from './plugins/fetch';


const initKeycloak = async () => {
  const kauth = new KeycloakPlugin({
    realm: 'meerkat',
    url: 'https://accounts.morgansolar.com/auth',
    clientId: 'devtools'
  });

  const loggedin = await kauth.init({
    onLoad: 'login-required',
    promiseType: 'native',
    checkLoginIframe: false
  });

  if (!loggedin) {
    // eslint-disable-next-line no-alert
    alert('Loggin failed');
  }

  Vue.use(kauth);
  return kauth;
};


const initApi = (kauth) => {
  const api = new ApiPlugin('pythonApi',
    process.env.VUE_APP_PYTHON_API_URL, async (opt) => {
      const token = await kauth.getValidToken();
      const authHeaders = { Authorization: `Bearer ${token}` };
      opt.headers = { ...authHeaders, ...opt.headers };
      return opt;
    });
  Vue.use(api);

  const daqApi = new ApiPlugin('daqApi',
    process.env.VUE_APP_DAQ_API_URL, async (opt) => {
      const token = await kauth.getValidToken();
      const authHeaders = { Authorization: `Bearer ${token}` };
      opt.headers = { ...authHeaders, ...opt.headers };
      return opt;
    });
  Vue.use(daqApi);
};


export default async function init() {
  const kauth = await initKeycloak();
  Vue.use(FetchPlugin);
  initApi(kauth);
}
